const BASE_URL = "https://core.andreis-vibes.ru";

const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : null;
};


const getShiftById = async (shiftId) => {
    const token = getCookie("authToken"); 
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/${shiftId}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,  
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при получении смены");
      }
  
      const shiftData = await response.json(); 
      return shiftData;  
  
    } catch (error) {
      console.error("Ошибка при получении смены:", error);
      throw error;  
    }
  };

  const getActualShift = async () => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/actual`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при получении актуальной смены");
      }
  
      const shiftData = await response.json();
      return shiftData;
  
    } catch (error) {
      console.error("Ошибка при получении актуальной смены:", error);
      throw error;
    }
  };
  
  const getNextShift = async (findMe = true) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/next?find_me=${findMe}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при получении следующей смены");
      }
  
      const shiftData = await response.json();
      return shiftData;
  
    } catch (error) {
      console.error("Ошибка при получении следующей смены:", error);
      throw error;
    }
  };

  const getWhatCanIDo = async () => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/what_can_i_do`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при получении информации о состоянии");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при получении информации о состоянии:", error);
      throw error;
    }
  };
  
  const enterShift = async (shiftId) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/${shiftId}/enter`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при входе на смену");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при входе на смену:", error);
      throw error;
    }
  };
  
  const setOpeningCash = async (shiftId, amount) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/${shiftId}/opening`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount }),
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при установке выручки на момент открытия");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при установке выручки на момент открытия:", error);
      throw error;
    }
  };
  
  const setClosingCash = async (shiftId, amount) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/${shiftId}/closing`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount }),
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при установке выручки на момент закрытия");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при установке выручки на момент закрытия:", error);
      throw error;
    }
  };
  
  const exitShift = async (shiftId) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/${shiftId}/exit`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при выходе со смены");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при выходе со смены:", error);
      throw error;
    }
  };
  

  export { 
    getShiftById,
    getActualShift,
    getNextShift,
    getWhatCanIDo,
    enterShift,
    setOpeningCash,
    setClosingCash,
    exitShift
  };
  
  