import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ButtonMain from "../../../CommonComponents/ButtonMain";
import { fontSizes } from "../../../CommonComponents/Sizes.jsx";
import CustomInput from "../../../CommonComponents/Input.jsx";
import {
  getWhatCanIDo,
  getNextShift,
  getActualShift,
  enterShift,
  setOpeningCash,
  setClosingCash,
  exitShift,
  getShiftById,
} from "../../../../shared/api/Management/StatusWidget.jsx";

const pxToRem = (px) => `${px / 16}rem`;

const WidgetContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: ${pxToRem(1800)};

  @media (max-width: 1440px) {
    max-width: ${pxToRem(1200)};
  }

  @media (max-width: 1024px) {
    max-width: ${pxToRem(900)};
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${pxToRem(4)};
  position: relative;
  width: 100%;
  max-width: ${pxToRem(1800)};

  @media (max-width: 1440px) {
    max-width: ${pxToRem(1200)};
    max-height: ${pxToRem(66)};
  }

  @media (max-width: 1024px) {
    max-width: ${pxToRem(900)};
  }
`;

const BackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${pxToRem(4)};
  opacity: 80%;
  background: #281e71;
  mix-blend-mode: multiply;
  z-index: -1;
`;

const StatusText = styled.span`
  font-size: ${pxToRem(32)};
  color: #fff;
  background-color: #101820;
  padding: ${pxToRem(22)} ${pxToRem(37)};
  border-radius: ${pxToRem(4)};

  br {
    display: none;
  }

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
    padding: ${(props) =>
      props.smaller
        ? `${pxToRem(21)} ${pxToRem(30)};`
        : `${pxToRem(10)} ${pxToRem(30)};`};

    br {
      display: inline;
    }
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.button};
    padding: ${(props) =>
      props.smaller
        ? `${pxToRem(21)} ${pxToRem(24)};`
        : `${pxToRem(10)} ${pxToRem(24)};`};
    br {
      display: inline;
    }
  }
`;

const AdditionalText = styled.span`
  font-size: ${pxToRem(32)};
  color: #fff;
  margin-left: ${pxToRem(31)};

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
    margin-left: ${pxToRem(26)};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.button};
    margin-left: ${pxToRem(20)};
  }
`;

const Timer = styled.span`
  font-size: ${pxToRem(40)};
  color: #fff;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1440px) {
    font-size: ${pxToRem(34)};
  }

  @media (max-width: 1024px) {
    font-size: ${pxToRem(28)};
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 46.3%;

  @media (max-width: 1440px) {
    width: 40%;
  }

  @media (max-width: 1024px) {
    width: 35%;
  }
`;

const Input = styled(CustomInput)`
  padding: ${pxToRem(28)} ${pxToRem(49)};
  &:focus {
    outline: 0.1875em solid rgba(149, 224, 178, 1);
    border-bottom: 0.1875em solid rgba(16, 24, 32, 1);
  }

  @media (max-width: 1440px) {
    padding: ${pxToRem(20)} ${pxToRem(40)};
  }

  @media (max-width: 1024px) {
    padding: ${pxToRem(20)} ${pxToRem(32)};
  }
`;

const ActionButton = styled(ButtonMain)`
  padding: ${pxToRem(30)} ${pxToRem(12)};
  font-size: ${fontSizes.button};

  @media (max-width: 1440px) {
    font-size: ${fontSizes.adaptLarge};
    padding: ${pxToRem(23)} ${pxToRem(10)};
    font-size: ${pxToRem(18)};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptLarge};
    padding: ${pxToRem(24)} ${pxToRem(8)};
    font-size: ${pxToRem(16)};
  }
`;
const ShiftWidget = () => {
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [currentStatus, setCurrentStatus] = useState("waiting");
  const [cashAmount, setCashAmount] = useState("");
  const [nextShift, setNextShift] = useState(null);
  const [actualShift, setActualShift] = useState(null);

  const updateTimer = (startTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const diff = Math.floor((start - now) / 1000);
    
    if (diff <= 0) {
      setTimer({ hours: 0, minutes: 0, seconds: 0 });
      return;
    }

    setTimer({
      hours: Math.floor(diff / 3600),
      minutes: Math.floor((diff % 3600) / 60),
      seconds: diff % 60,
    });
  };

  const canOpenShift = () => {
    if (!nextShift?.start_time) return false;
    const diff = (new Date(nextShift.start_time) - new Date()) / 1000 / 60;
    return diff <= 10;
  };

  const canCloseShift = () => {
    if (!actualShift?.end_time) return false;
    const diff = (new Date(actualShift.end_time) - new Date()) / 1000 / 60;
    return diff <= 10;
  };

  const loadData = async () => {
    try {
      const [nextData, actualData, statusData] = await Promise.all([
        getNextShift(true),
        getActualShift(),
        getWhatCanIDo(),
      ]);

      setNextShift(nextData);
      setActualShift(actualData);

      switch (statusData.status) {
        case "nothing":
        case "wait_next_shift":
          setCurrentStatus("no-active");
          break;
        case "enter":
          setCurrentStatus("waiting");
          break;
        case "enter_cash_amount_at_opening":
          setCurrentStatus("opening");
          break;
        case "be_on_shift":
          setCurrentStatus("open");
          break;
        case "enter_cash_amount_at_closing":
          setCurrentStatus("closing");
          break;
        case "exit":
          setCurrentStatus("waiting");
          break;
        default:
          setCurrentStatus("no-active");
      }

      if (nextData?.start_time) updateTimer(nextData.start_time);
    } catch (error) {
      console.error("Ошибка загрузки данных:", error);
    }
  };

  useEffect(() => {
    loadData();
    const interval = setInterval(loadData, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer(prev => {
        const { hours, minutes, seconds } = prev;
        if (seconds > 0) return { ...prev, seconds: seconds - 1 };
        if (minutes > 0) return { ...prev, minutes: minutes - 1, seconds: 59 };
        if (hours > 0) return { hours: hours - 1, minutes: 59, seconds: 59 };
        clearInterval(timerInterval);
        return prev;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const updateShiftData = async (shiftId) => {
    try {
      const updatedShift = await getShiftById(shiftId);
      
      if (currentStatus === "open" || currentStatus === "closing") {
        setActualShift(updatedShift);
      } else {
        setNextShift(updatedShift);
      }
      
      // Обновляем таймер если это актуальная смена
      if (updatedShift.start_time && !updatedShift.end_time) {
        updateTimer(updatedShift.start_time);
      }
    } catch (error) {
      console.error("Ошибка обновления данных смены:", error);
    }
  };

  const handleAction = async () => {
    try {
      const shiftId = currentStatus === "open" ? actualShift?.id : nextShift?.id;
      if (!shiftId) throw new Error("Не найден ID смены");

      switch (currentStatus) {
        case "waiting":
          if (!canOpenShift()) {
            alert("Смену можно открыть за 10 минут до начала");
            return;
          }
          await enterShift(shiftId);
          await updateShiftData(shiftId); // Обновляем данные смены
          setCurrentStatus("opening");
          break;

        case "opening":
          if (!cashAmount || isNaN(cashAmount)) {
            alert("Введите корректную сумму наличных");
            return;
          }
          await setOpeningCash(shiftId, Number(cashAmount));
          await updateShiftData(shiftId); // Получаем обновленные данные
          setCurrentStatus("open");
          setCashAmount("");
          break;

        case "open":
          if (!canCloseShift()) {
            alert("Смену можно закрыть за 10 минут до окончания");
            return;
          }
          await exitShift(shiftId);
          await updateShiftData(shiftId); // Обновляем перед закрытием
          setCurrentStatus("closing");
          break;

        case "closing":
          if (!cashAmount || isNaN(cashAmount)) {
            alert("Введите корректную сумму наличных");
            return;
          }
          await setClosingCash(shiftId, Number(cashAmount));
          await updateShiftData(shiftId); // Финальное обновление
          setCurrentStatus("waiting");
          setCashAmount("");
          break;

        default:
          break;
      }
      
      // Дополнительное обновление списка смен
      await loadData();
    } catch (error) {
      console.error("Ошибка операции:", error);
      alert(error.message || "Ошибка выполнения операции");
    }
  };

  const formatTime = ({ hours, minutes, seconds }) =>
    `${String(hours).padStart(2, "0")}ч : ${String(minutes).padStart(2, "0")}м : ${String(seconds).padStart(2, "0")}с`;

  return (
    <WidgetContainer>
      {currentStatus === "waiting" && (
        <StatusContainer>
          <BackgroundLayer />
          <StatusText>
            Статус смены: <br />
            <span style={{ color: "#FFFA8A" }}>ожидает открытия</span>
          </StatusText>
          <AdditionalText>Ваша смена начнется через:</AdditionalText>
          <Timer>{formatTime(timer)}</Timer>
          <ActionButton onClick={handleAction} disabled={!canOpenShift()}>
            Открыть смену
          </ActionButton>
        </StatusContainer>
      )}

      {currentStatus === "open" && (
        <StatusContainer>
          <BackgroundLayer />
          <StatusText>
            Статус смены:
            <br /> <span style={{ color: "#95E0B2" }}>смена открыта</span>
          </StatusText>
          <AdditionalText>До окончания смены осталось:</AdditionalText>
          <Timer>{formatTime(timer)}</Timer>
          <ActionButton onClick={handleAction} disabled={!canCloseShift()}>
            Закрыть смену
          </ActionButton>
        </StatusContainer>
      )}

      {currentStatus === "no-active" && (
        <StatusContainer>
          <BackgroundLayer />
          <StatusText>
            Статус смены:
            <br /> <strong>у вас нет активной смены</strong>
          </StatusText>
          <AdditionalText>Следующая смена через: </AdditionalText>
          <Timer>{formatTime(timer)}</Timer>
        </StatusContainer>
      )}

      {currentStatus === "opening" && (
        <StatusContainer>
          <BackgroundLayer />
          <StatusText smaller="smaller" style={{ color: "#95E0B2" }}>
            Открытие смены
          </StatusText>
          <AdditionalText>Введите количество денег в кассе</AdditionalText>
          <InputContainer>
            <Input
              type="number"
              placeholder="Введите значение..."
              value={cashAmount}
              onChange={(e) => setCashAmount(e.target.value)}
            />
            <ActionButton onClick={handleAction}>
              Подтвердить
            </ActionButton>
          </InputContainer>
        </StatusContainer>
      )}

      {currentStatus === "closing" && (
        <StatusContainer>
          <BackgroundLayer />
          <StatusText smaller="smaller">
            <strong>Закрытие смены</strong>
          </StatusText>
          <AdditionalText>Введите количество денег в кассе</AdditionalText>
          <InputContainer>
            <Input
              type="number"
              placeholder="Введите значение..."
              value={cashAmount}
              onChange={(e) => setCashAmount(e.target.value)}
            />
            <ActionButton onClick={handleAction}>
              Подтвердить
            </ActionButton>
          </InputContainer>
        </StatusContainer>
      )}
    </WidgetContainer>
  );
};

export default ShiftWidget;