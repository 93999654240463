import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { fontSizes } from "../CommonComponents/Sizes.jsx";
import {
  getSettings,
  updateSettings
} from "../../shared/api/Profile.jsx";

const pxToRem = (px) => `${px / 16}rem`;

const PanelContainer = styled.div`
  background: rgba(16, 24, 32, 1);
  padding: 0;
  border-radius: 4px;
`;

const PanelHeader = styled.div`
  padding: ${pxToRem(22)} ${pxToRem(44)};
  text-align: center;
  font-size: ${fontSizes.subheading};
  font-weight: bold;
  border-bottom: 2px solid #95E0B2;

  @media (max-width: 1920px) {
    padding: ${pxToRem(20)} ${pxToRem(35)};
    font-size: ${fontSizes.button};
  }

  @media (max-width: 1440px) {
    padding: ${pxToRem(15)} ${pxToRem(30)};
    font-size: 18px;
  }
`;

const SettingItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pxToRem(51)} ${pxToRem(22)} 0 ${pxToRem(22)};
  font-size: ${fontSizes.subheading};
  font-weight: 300;
  line-height: 28.13px;
  color: #fff;

  label {
    max-width: ${pxToRem(150)};
  }

  &:first-child {
    margin-top: -4px; 
  }

  &:last-child {
    margin-bottom: ${pxToRem(14)}; 
    padding: ${pxToRem(51)} ${pxToRem(22)};
  }

  @media (max-width: 1920px) {
    padding: ${pxToRem(35)} ${pxToRem(18)} 0 ${pxToRem(18)};
    font-size: ${fontSizes.button};

    &:last-child {
      margin-bottom: ${pxToRem(10)}; 
      padding: ${pxToRem(35)} ${pxToRem(18)};
    }
  }

  @media (max-width: 1440px) {
    padding: ${pxToRem(25)} ${pxToRem(14)} 0 ${pxToRem(14)};
    font-size: 16px;
    line-height: 120%;

    &:last-child {
      margin-bottom: ${pxToRem(10)}; 
      padding: ${pxToRem(25)} ${pxToRem(14)};
    }
  }
`;

const Switch = styled.input`
  width: 5rem;
  height: 2.296rem;
  background: rgba(40, 30, 113, 1); /* Фон в состоянии "выключен" */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 129px;
  appearance: none;
  cursor: pointer;
  position: relative;
  outline: none;

  &:checked {
    background: rgba(73, 44, 255, 1); /* Фон в состоянии "включен" */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &:before {
    content: "";
    width: 26.21px;
    height: 26.21px;
    background: rgba(73, 44, 255, 1); /* Круг в состоянии "выключен" */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    position: absolute;
    top: 15%;
    left: 8px;
    transition: transform 0.3s, background 0.3s;
  }

  &:checked:before {
    transform: translateX(2.5rem);
    background: rgba(149, 224, 178, 1); /* Круг в состоянии "включен" */
    left: -3px;
  }

  @media (max-width: 2560px) {
    &:checked:before {
      left: 6px;
    }
  }

  @media (max-width: 1920px) {
    width: 4.25rem;
    height: 2rem;

    &:before {
      top: 9%;
      left: 6px;
    }

    &:checked:before {
      left: -3px;
    }
  }

  @media (max-width: 1440px) {
    width: 2.4rem;
    height: 1.2rem;

    &:before {
      top: 14%;
      left: 4px;
      width: 14.21px;
      height: 14.21px;
    }

    &:checked:before {
      transform: translateX(1rem);
      left: 4px;
    }
  }
`;

const NotificationsPanel = () => {
  const [notificationSettings, setNotificationSettings] = useState(null); // Для хранения настроек
  const [error, setError] = useState(null);
  
  const settings = [
    { label: "Включить все", id: "notifications_enabled" },
    { label: "SMS", id: "sms_notifications" },
    { label: "E-mail", id: "email_notifications" },
    { label: "Телеграм", id: "telegram_notifications" },
  ];

  // Получение настроек
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        setNotificationSettings(data.notifications); // Устанавливаем настройки уведомлений
      } catch (err) {
        setError("Не удалось загрузить настройки");
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  const handleToggle = async (id) => {
    if (!notificationSettings) return;

    const updatedSettings = { ...notificationSettings };

    // Обработка переключателя "Включить все"
    if (id === "notifications_enabled") {
      updatedSettings.notifications_enabled = !notificationSettings.notifications_enabled;

      // Если "Включить все" отключено, все остальные переключатели отключаются
      if (!updatedSettings.notifications_enabled) {
        Object.keys(updatedSettings).forEach((key) => {
          if (key !== "notifications_enabled") {
            updatedSettings[key] = false;
          }
        });
      }
    } else {
      // Обработка переключателей уведомлений (SMS, E-mail, Telegram)
      if (notificationSettings.notifications_enabled) {
        updatedSettings[id] = !notificationSettings[id];
      }
    }

    try {
      setNotificationSettings(updatedSettings);
      await updateSettings({ notifications: updatedSettings });
      console.log("Настройки успешно сохранены:", updatedSettings);
    } catch (err) {
      setError("Ошибка при обновлении настроек");
      console.error("Ошибка сохранения настроек:", err);
    }
  };

  // Проверка на наличие данных
  if (!notificationSettings) {
    return error ? <div>{error}</div> : <div>Загрузка настроек...</div>;
  }

  return (
    <PanelContainer>
      <PanelHeader>Уведомления</PanelHeader>
      {settings.map((setting) => (
        <SettingItem key={setting.id}>
          <label htmlFor={setting.id}>{setting.label}</label>
          <Switch
            type="checkbox"
            id={setting.id}
            checked={notificationSettings?.[setting.id] || false}
            onChange={() => handleToggle(setting.id)}
          />
        </SettingItem>
      ))}
    </PanelContainer>
  );
};

export default NotificationsPanel;
