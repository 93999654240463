import styled, { css } from "styled-components";
import { spacing, fontSizes } from "../CommonComponents/Sizes.jsx";
import ButtonMain from "../CommonComponents/ButtonMain.jsx";
import CustomInput from "../CommonComponents/Input.jsx";

const pxToRem = (px) => `${px / 16}rem`;

export const Profile = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const ProfileContainer = styled.div`
  position: relative;
  padding-bottom: ${pxToRem(36)};
  justify-content: center;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr;
  margin: ${pxToRem(40)} ${pxToRem(60)};
  overflow: hidden;

  @media (max-width: 1920px) {
    margin: ${pxToRem(30)} ${pxToRem(50)};
  }

  @media (max-width: 1440px) {
    margin: ${pxToRem(25)} ${pxToRem(40)};
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 80%;
  background: rgba(40, 30, 113, 0.8);
  mix-blend-mode: multiply;
  z-index: -1;
`;

export const SubTitleContainer = styled.div`
  position: absolute;
  padding: ${pxToRem(11)} ${pxToRem(58)};
  background: rgba(16, 24, 32, 1);
  border-radius: 4px;
  display: inline-block;

  @media (max-width: 1920px) {
    padding: ${pxToRem(9)} ${pxToRem(48)};
  }
  @media (max-width: 1440px) {
    padding: ${pxToRem(9)} ${pxToRem(38)};
  }
`;

export const SubTitle = styled.h1`
  font-size: ${fontSizes.heading};
  font-weight: 500;
  text-transform: uppercase;
  color: white;

  @media (max-width: 1920px) {
    font-size: ${fontSizes.heading};
  }

  @media (max-width: 1440px) {
    font-size: ${fontSizes.subheading};
  }
`;

export const Info = styled.div`
  margin-top: ${pxToRem(95)};
  padding: 0 ${pxToRem(64)};

  @media (max-width: 1920px) {
    margin-top: ${pxToRem(95)};
    padding: 0 ${pxToRem(50)};
  }

  @media (max-width: 1440px) {
    margin-top: ${pxToRem(80)};
    padding: 0 ${pxToRem(30)};
  }
`;

export const ProfileInfo = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 18.61% 2fr 18.1%;
  gap: ${pxToRem(40)};

  @media (max-width: 1920px) {
    gap: ${pxToRem(35)};
  }

  @media (max-width: 1440px) {
    gap: ${pxToRem(25)};
  }
`;

export const ProfileFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background: ${(props) =>
    props.isEditing ? "transparent" : "rgba(16, 24, 32, 1)"};
  align-items: ${(props) => props.isLarge ? '' : 'center'};
  position: relative;
  height: ${(props) =>
    props.isLarge ? (props.isEditing ? "6.3rem" : "10rem") : "3.375rem"};
  width: 100%;

  @media (max-width: 1920px) {
    height: ${(props) =>
      props.isLarge ? (props.isEditing ? "6.525rem" : "10rem") : "3.038rem"};
  }

  @media (max-width: 1440px) {
    height: ${(props) =>
      props.isLarge ? (props.isEditing ? "5.5rem" : "7rem") : "2.362rem"};
  }
`;

export const FieldLabel = styled.div`
  position: ${(props) => (props.isEditing ? "absolute" : "relative")};
  display: flex;
  font-size: ${fontSizes.subheading};
  font-weight: 500;
  background: rgba(40, 30, 113, 1);
  height: 3.2rem;
  align-items: center;
  box-shadow: 0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.25);
  padding: ${pxToRem(13)} ${pxToRem(35)};
  border-radius: 0.25rem;
  white-space: nowrap;

  ${(props) =>
    props.isCareerGoals &&
    `
    top: 0;
    left: 0;
    padding: 1rem ${pxToRem(44)};
    border-radius: 4px;
  `}

  @media (max-width: 1920px) {
    font-size: ${fontSizes.button};
    padding: ${pxToRem(11)} ${pxToRem(20)};
  }

  @media (max-width: 1440px) {
    font-size: 16px;
    padding: ${pxToRem(9)} ${pxToRem(14)};
    height: 2.4rem;
  }
`;

export const Input = styled(CustomInput)`
  font-size: ${fontSizes.button};
  border-radius: 0.25rem;
  width: 100%;
  box-sizing: border-box;
  padding-left: ${(props) =>
    props.isEditing ? `calc(${pxToRem(40)} + ${props.labelWidth}px)` : pxToRem(40)};\
  &:focus {
    outline: none;
    border-bottom: 0.1875em solid rgba(16, 24, 32, 1);
  }

  @media (max-width: 1920px) {
    padding-left: ${(props) =>
      props.isEditing ? `calc(${pxToRem(30)} + ${props.labelWidth}px)` : pxToRem(30)};
    font-size: ${fontSizes.adaptLarge};
  }

  @media (max-width: 1440px) {
    height: ${(props) => (props.isLarge ? "6rem" : "2.75rem")};
    padding-left: ${(props) =>
      props.isEditing ? `calc(${pxToRem(30)} + ${props.labelWidth}px)` : pxToRem(30)};
    font-size: ${fontSizes.adaptMedium};
  }
`;


export const FieldValue = styled.div`
  font-size: ${fontSizes.subheading};
  font-weight: 300;
  margin-left: ${spacing.large};

  ${(props) =>
    props.isLarge &&
    `
    height: ${pxToRem(161)};
    display: flex;
    padding-top: 0.8rem;
    overflow-y: auto;
  `}

  @media (max-width: 1920px) {
    font-size: ${fontSizes.button};
    margin-left: ${spacing.medium};

    ${(props) =>
      props.isLarge &&
      `
      height: calc(161px * 0.8);
      padding: 0.8rem;
    `}
  }

  @media (max-width: 1440px) {
    font-size: 16px;
    margin-left: ${spacing.xmedium};

    ${(props) =>
      props.isLarge &&
      `
      height: calc(161px * 0.6);
      padding: 0.8rem;
    `}
  }
`;

export const ErrorText = styled.p`
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: ${pxToRem(10)};
`;

export const SocialIcon = styled.img`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  &:hover {
    opacity: 0.8;
  }
  object-fit: contain; /* Гарантирует, что иконки не выходят за пределы контейнера */

  @media (max-width: 1920px) {
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
  }

  @media (max-width: 1440px) {
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
  }
`;

export const TrashIcon = styled.img`
  position: absolute;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  right: ${pxToRem(14)};
  &:hover {
    opacity: 0.8;
  }
  object-fit: contain; /* Гарантирует, что иконки не выходят за пределы контейнера */

  @media (max-width: 1920px) {
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
  }

  @media (max-width: 1440px) {
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
  }
`;

export const Photo = styled.img`
  width: 100%;
  height: ${pxToRem(410)};
  border-radius: 6px;
  object-fit: cover;
  z-index: 3;

  @media (max-width: 1440px) {
    // width: 12rem;
    height: 17.5rem;
  }
`;

export const EditProfileButton = styled(ButtonMain)`
  margin-left: auto;
  margin-top: 1rem;
  height: 3.125rem;
  padding: ${pxToRem(13)} ${pxToRem(39)};

  @media (max-width: 1920px) {
    padding: ${pxToRem(10)} ${pxToRem(30)};
    height: 2.5rem;
  }

  @media (max-width: 1440px) {
    margin-top: ${(props) => (props.isEditing ? "0rem" : "0.4rem")};
    padding: ${pxToRem(10)} ${pxToRem(30)};
    height: 2.5rem;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // max-width: 20rem;
  // max-width: none;

  @media (max-width: 1920px) {
    //  max-width: 17rem;
  }

  @media (max-width: 1440px) {
    //  max-width: 12rem;
  }
`;

export const UserInfoContainer = styled.div`
  width: 100%;
  height: 6.6rem;
  margin-top: -0.2rem;
  z-index: 2;
  background: rgba(16, 24, 32, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  border-bottom: 3px solid #95e0b2;

  @media (max-width: 1920px) {
    height: 5.125rem;
    padding: 0rem 1rem;
  }

  @media (max-width: 1440px) {
    height: 4.5rem;
    padding: 0rem 0.6rem;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${pxToRem(20)} ${pxToRem(17)};

  @media (max-width: 1920px) {
    padding: ${pxToRem(11)} 0;
  }

  @media (max-width: 1440px) {
    padding: ${pxToRem(8)} 0;
  }
`;

export const FullName = styled.h2`
  font-size: ${fontSizes.subheading};
  color: #fff;
  font-weight: 300;
  margin: 0;

  @media (max-width: 1920px) {
    font-size: 18px;
  }

  @media (max-width: 1440px) {
    font-size: 14px;
  }
`;

export const Position = styled.p`
  font-size: ${fontSizes.subheading};
  color: #95e0b2;
  font-weight: 300;
  margin-top: ${pxToRem(5)};

  @media (max-width: 1920px) {
    font-size: 18px;
  }

  @media (max-width: 1440px) {
    font-size: 14px;
  }
`;

export const GenderIcon = styled.img`
  width: ${pxToRem(26)};
  height: ${pxToRem(26)};
  margin-left: auto;

  @media (max-width: 1920px) {
    width: ${pxToRem(22)};
    height: ${pxToRem(22)};
  }

  @media (max-width: 1440px) {
    width: ${pxToRem(16)};
    height: ${pxToRem(18)};
  }
`;

export const SocialMediaFieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background: rgba(73, 44, 255, 1);
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  align-items: center;
  margin-bottom: ${(props) => (props.activeSocial ? "0" : "1rem")};
  height: 5.125rem;
  border-radius: 3px;

  @media (max-width: 1440px) {
    height: 4rem;
  }
`;

export const SocialMediaTitle = styled.h3`
  display: flex;
  font-size: ${fontSizes.subheading};
  font-weight: 500;
  background: rgba(16, 24, 32, 1);
  height: 5.125rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: ${pxToRem(309)};
  border-radius: 3px;

  @media (max-width: 1920px) {
    font-size: ${fontSizes.button};
    width: ${pxToRem(280)};
  }

  @media (max-width: 1440px) {
    height: 4rem;
    font-size: 16px;
    width: ${pxToRem(250)};
  }
`;

export const SocialMediaLinks = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0 ${pxToRem(30)};
  padding: ${pxToRem(10)} 0;

  &::-webkit-scrollbar {
    height: ${pxToRem(8)};
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(149, 224, 178, 1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(40, 30, 113, 0.8);
    border-radius: 4px;
  }

  @media (max-width: 1920px) {
    padding-bottom: ${pxToRem(10)};
    margin: 0 ${pxToRem(15)};
  }

  @media (max-width: 1440px) {
    padding-bottom: ${pxToRem(8)};
    margin: 0 ${pxToRem(15)};
    &::-webkit-scrollbar {
      height: ${pxToRem(6)};
    }
  }
`;

export const RightColumn = styled.div`
  position: relative; 
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    margin-bottom: 1rem;
  }

  & > :nth-last-child(3) {
    margin-bottom: ${(props) => (props.activeSocial ? "0" : "1.4375rem")};
  }

  @media (max-width: 1440px) {
    & > * {
    margin-bottom: ${(props) => (props.isEditing ? "1rem" : "0.6rem")};
    }
    & > :nth-last-child(3) {
    margin-bottom: ${(props) =>
      props.activeSocial && props.isEditing ? "0" : "1rem"};
    }
`;

export const StatusIcon = styled.img`
  position: absolute;
  right: ${pxToRem(13)};
  height: ${pxToRem(24)};
  width: ${pxToRem(18)};
  margin-top: ${pxToRem(2)};

  @media (max-width: 1920px) {
    height: ${pxToRem(21)};
    width: ${pxToRem(15)};
  }

  @media (max-width: 1440px) {
    height: ${pxToRem(18)};
    width: ${pxToRem(12)};
  }
`;

export const CopyIcon = styled.img`
  position: absolute;
  right: ${pxToRem(45)};
  height: ${pxToRem(21)};
  width: ${pxToRem(24)};
  margin-top: ${pxToRem(3)};
  cursor: pointer;

  @media (max-width: 1920px) {
    height: ${pxToRem(18)};
    width: ${pxToRem(21)};
    right: ${pxToRem(40)};
  }

  @media (max-width: 1440px) {
    height: ${pxToRem(16)};
    width: ${pxToRem(16)};
    right: ${pxToRem(35)};
  }
`;

export const UploadPhotoButton = styled(ButtonMain)`
  width: 100%;
  padding: 0;
  margin-top: 2rem;
  height: 3.125rem;

  @media (max-width: 1920px) {
    height: 3.125rem;
    margin-top: 1rem;
  }

  @media (max-width: 1440px) {
    height: 3.125rem;
    margin-top: 1rem;
  }
`;

export const NotificationsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isEditing ? "0rem" : "1rem")};
`;

//isEditing Панель добавления соцсетей

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SearchInput = styled(CustomInput)`
  padding: ${pxToRem(14)};
  height: 3.375rem;

  @media (max-width: 1920px) {
    height: 2.75rem;
    font-size: ${fontSizes.adaptLarge};
  }

  @media (max-width: 1440px) {
    height: 2.75rem;
    font-size: ${fontSizes.adaptSmall};
    padding: ${pxToRem(10)};
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  right: ${pxToRem(14)};
  cursor: pointer;

  @media (max-width: 1920px) {
    width: ${pxToRem(22)};
    height: ${pxToRem(22)};
  }

  @media (max-width: 1440px) {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    right: ${pxToRem(10)};
  }
`;

export const SocialsList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  background: rgba(73, 44, 255, 1);
  gap: ${pxToRem(16)};
  padding: ${pxToRem(22)} ${pxToRem(24)};
  max-height: 19rem;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: ${pxToRem(27)};
  }

  &::-webkit-scrollbar-track {
    background: rgba(40, 30, 113, 1);
    height: ${pxToRem(263)};
    border-radius: ${pxToRem(129)};
    margin: ${pxToRem(22)} ${pxToRem(8)} ${pxToRem(22)} 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(149, 224, 178, 1);
    border-radius: 20px;
    height: ${pxToRem(50)};
  }

  @media (max-width: 1920px) {
    max-height: 18.4rem;
  }

  @media (max-width: 1440px) {
    gap: ${pxToRem(12)};
    padding: ${pxToRem(14)} ${pxToRem(8)};
    max-height: 16.4rem;

    &::-webkit-scrollbar {
      width: ${pxToRem(17)};
    }

    &::-webkit-scrollbar-track {
      margin: ${pxToRem(22)} ${pxToRem(8)} ${pxToRem(22)} 0;
    }
  }
`;

export const SocialItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem(20)};
  cursor: pointer;
  flex-shrink: 0;

  @media (max-width: 1440px) {
    gap: ${pxToRem(14)};
  }
`;

export const SocialName = styled.div`
  flex: 1;
  color: rgba(16, 24, 32, 1);
  font-size: ${fontSizes.inputText};
  font-weight: 600;
  margin-left: ${pxToRem(8)};
  cursor: pointer;

  @media (max-width: 1920px) {
    margin-left: ${pxToRem(6)};
    font-size: 18px;
  }

  @media (max-width: 1440px) {
    margin-left: ${pxToRem(4)};
    font-size: 16px;
  }
`;

export const EditSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const EditInput = styled(CustomInput)`
  padding: ${pxToRem(14)};
  height: 3.3125rem;

  @media (max-width: 1920px) {
    height: 2.75rem;
    font-size: ${fontSizes.adaptLarge};
  }

  @media (max-width: 1440px) {
    height: 2.75rem;
    font-size: ${fontSizes.adaptMedium};
    padding: ${pxToRem(12)};
  }
`;

export const ApplyButton = styled(ButtonMain)`
  position: absolute;
  padding: ${pxToRem(14)};
  
  ${({ variant }) =>
    variant === "alternative" &&
    css`
      top: 0rem;
      right: 0rem;

      @media (max-width: 1920px) {
        padding: ${pxToRem(12)};
      }

      @media (max-width: 1440px) {
        padding: ${pxToRem(14)};
      }
    `}

  ${({ variant }) =>
    variant === "regular" &&
    css`
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: 2560px) {
        top: 6rem;
      }

      @media (max-width: 1920px) {
        padding: ${pxToRem(14)};
        top: 5rem;
      }

      @media (max-width: 1440px) {
        padding: ${pxToRem(12)};
        top: 5rem;
      }
    `}

  &:hover {
    background-color: #3f51b5;
  }
`;
