const BASE_URL = "https://core.andreis-vibes.ru";

const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : null;
};

// Функция для получения текущего сеанса
const getCurrentSession = async () => {
  try {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при получении текущего сеанса");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при получении текущего сеанса:", error);
    throw error;
  }
};

// Функция для получения всех сеансов
const getAllSessions = async () => {
  try {
    const token = getCookie("authToken"); 
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при получении всех сеансов");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при получении всех сеансов:", error);
    throw error;
  }
};

// Функция для проверки статуса токена
const checkTokenStatus = async () => {
  try {
    const token = getCookie("authToken"); 
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/check`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при проверке статуса токена");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при проверке статуса токена:", error);
    throw error;
  }
};

// Функция для удаления всех сеансов
const deleteAllSessions = async () => {
  try {
    const token = getCookie("authToken"); 
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/all`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Ошибка при удалении всех сеансов: ${errorData.message}`);
    }

    return { success: true, message: "Все сеансы успешно удалены" };

  } catch (error) {
    console.error("Ошибка при удалении всех сеансов:", error);
    throw error;
  }
};

// Функция для получения статистики по сеансу
const getSessionStats = async (sessionId) => {
  try {
    const token = getCookie("authToken"); 
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/${sessionId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при получении статистики по сеансу");
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("Ошибка при загрузке статистики по сеансу:", error);
    throw error;
  }
};

// Функция для удаления сессии
const deleteSession = async (sessionId) => {
  try {
    const token = getCookie("authToken"); 
    if (!token) throw new Error("Токен отсутствует, выполните вход");

    const response = await fetch(`${BASE_URL}/session/${sessionId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Ошибка при удалении сессии");
    }

    return { success: true };

  } catch (error) {
    console.error("Ошибка при удалении сессии:", error);
    throw error;
  }
};

export { getCurrentSession, getAllSessions, checkTokenStatus, deleteAllSessions, getSessionStats, deleteSession };
