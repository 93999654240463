import React, { useEffect, useState } from "react";
import AnimatedGradientBackground from "../../AnimatedGradient.jsx";
import ProfileHeader from "../ProfileHeader/ProfileHeader.jsx";
import * as Styled from "./StyleMainManagement.jsx";
import ScheduleTable from "./Schedule/ScheduleTable.jsx";
import ShiftWidget from "./StatusWidget/Status.jsx";

const MainManagement = () => {
  const [activeAdmin, setActiveAdmin] = useState(null);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const currentUser = localStorage.getItem("full_name");
    setActiveAdmin(currentUser);

    const mockAdmins = [
      { id: 1, name: "Иванов Иван", color: "#FFFA8A" },
      { id: 2, name: "Петров Петр", color: "#492CFF" },
      { id: 3, name: "Сидоров Сидор", color: "#2A9049" },
      { id: 4, name: "Катя, просто Катя", color: "pink" },
    ];

    if (currentUser && !mockAdmins.some(admin => admin.name === currentUser)) {
      mockAdmins.push({ id: 5, name: currentUser, color: "#FF5733" }); // Новый цвет
    }

    setAdmins(mockAdmins);
  }, []);

  const sortedAdmins = [...admins].sort((a, b) =>
    a.name === activeAdmin ? -1 : b.name === activeAdmin ? 1 : 0
  );

  const handleDragStart = (event, color, employee_id) => {
    event.dataTransfer.setData("color", color);
    event.dataTransfer.setData("employee_id", employee_id); 
  };

  return (
    <Styled.MainManagement>
      <AnimatedGradientBackground />
      <ProfileHeader />

      <Styled.MainContent>
        <ShiftWidget />
        <Styled.CenteredContentWrapper>
          <ScheduleTable />
          <Styled.AdminColumn>
            <Styled.AdminColumnBackground />
            <Styled.AdminTitle>Администраторы</Styled.AdminTitle>
            {sortedAdmins.map((admin, index) => (
              <Styled.AdminItem
                key={index}
                isActive={admin.name === activeAdmin} 
              >
                <Styled.AdminCircle
                  color={admin.color}
                  draggable
                  onDragStart={(e) => handleDragStart(e, admin.color, admin.id)}
                />
                <Styled.AdminName
                  draggable
                  onDragStart={(e) => handleDragStart(e, admin.color, admin.id)}
                >
                  {admin.name}
                </Styled.AdminName>
              </Styled.AdminItem>
            ))}
          </Styled.AdminColumn>
        </Styled.CenteredContentWrapper>
      </Styled.MainContent>
    </Styled.MainManagement>
  );
};

export default MainManagement;