import styled from "styled-components";
import { fontSizes } from "../../CommonComponents/Sizes.jsx";

const pxToRem = (px) => `${px / 16}rem`;

export const MainManagement = styled.div``;

export const MainContent = styled.div`
  margin-top: ${pxToRem(52)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    margin-top: ${pxToRem(40)};
  }

  @media (max-width: 1024px) {
    margin-top: ${pxToRem(30)};
  }
`;

export const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${pxToRem(40)};
  max-width: ${pxToRem(1800)};
  width: 100%;
  gap: ${pxToRem(60)};
  justify-content: space-between;

  @media (max-width: 1440px) {
    margin-top: ${pxToRem(40)};
    max-width: ${pxToRem(1200)};
    gap: ${pxToRem(40)};
  }

  @media (max-width: 1024px) {
    margin-top: ${pxToRem(30)};
    max-width: ${pxToRem(900)};
    gap: ${pxToRem(40)};
  }
`;

export const AdminColumn = styled.div`
  width: 15%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // @media (max-width: 1440px) {
  //   width: 15%;
  // }

  @media (max-width: 1024px) {
    width: 20%;
  }
`;

export const AdminColumnBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 80%;
  background: #281e71;
  mix-blend-mode: multiply;
  z-index: -1;
  border-radius: ${pxToRem(4)};
`;

export const AdminTitle = styled.h3`
  border-radius: ${pxToRem(4)};
  padding: ${pxToRem(25)};
  text-align: center;
  font-size: ${fontSizes.button};
  font-weight: 600;
  border-bottom: 2px solid #95e0b2;
  background: #101820;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.adaptMedium};
    padding: ${pxToRem(20)};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptSmall};
    padding: ${pxToRem(15)};
  }
`;

export const AdminItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ isActive }) => (isActive ? "0" : "17px")};
  margin-left: ${({ isActive }) => (isActive ? "0" : "17px")};
  background-color: ${({ isActive }) => (isActive ? "#101820" : "transparent")};
  border: ${({ isActive }) => (isActive ? "2px solid #95E0B2" : "none")};
  border-radius: ${pxToRem(4)};
  padding-top: ${({ isActive }) => (isActive ? pxToRem(6) : "0")};
  padding-bottom: ${({ isActive }) => (isActive ? pxToRem(6) : "0")};
  padding-left: ${({ isActive }) => (isActive ? pxToRem(18) : "0")};
  &:last-child {
    margin-bottom: ${pxToRem(17)};
  }

  @media (max-width: 1440px) {
    margin-top: ${({ isActive }) => (isActive ? "0" : "10px")};
    margin-left: ${({ isActive }) => (isActive ? "0" : "10px")};
    padding-left: ${({ isActive }) => (isActive ? pxToRem(12) : "0")};
  }

  @media (max-width: 1024px) {
    margin-top: ${({ isActive }) => (isActive ? "0" : "8px")};
    margin-left: ${({ isActive }) => (isActive ? "0" : "8px")};
    padding-left: ${({ isActive }) => (isActive ? pxToRem(8) : "0")};
  }
`;

export const AdminCircle = styled.div`
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: ${pxToRem(22)};
  min-width: ${pxToRem(32)};
  min-height: ${pxToRem(32)};

  @media (max-width: 1440px) {
    width: ${pxToRem(25)};
    height: ${pxToRem(25)};
    margin-right: ${pxToRem(16)};
    min-width: ${pxToRem(25)};
    min-height: ${pxToRem(25)};
  }

  @media (max-width: 1024px) {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    margin-right: ${pxToRem(14)};
    min-width: ${pxToRem(20)};
    min-height: ${pxToRem(20)};
  }
`;

export const AdminName = styled.span`
  font-size: ${fontSizes.subheading};
  color: #ffffff;
  margin-right: 2px;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptMedium};
  }
`;
