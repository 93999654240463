import React, { useState, useEffect } from "react";
import Morning from "../../../../img/Calendar/Morning.svg";
import Day from "../../../../img/Calendar/Day.svg";
import Night from "../../../../img/Calendar/Night.svg";
import { ReturnButton, ForwardButton } from "../../../../img/icons.js";
import * as Styled from "./StyleScheduleTable.jsx";
import {
  getShiftsByMonth,
  addEmployeeToShift,
  addMeToShift,
  removeMeFromShift,
} from "../../../../shared/api/Management/Schedule.jsx";

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
const daysOfWeek = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];

const getDaysInMonth = (year, month) => {
  const date = new Date(year, month, 1);
  const days = [];
  const firstDayIndex = (date.getDay() + 6) % 7; // Преобразуем Sunday (0) в последний день (6)

  // Добавляем пустые дни перед началом месяца
  for (let i = 0; i < firstDayIndex; i++) {
    days.push(null);
  }

  // Добавляем дни месяца
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  // Добавляем пустые дни в конец, чтобы неделя была полной
  while (days.length % 7 !== 0) {
    days.push(null);
  }

  return days;
};

const getWeeksInMonth = (days) => Math.ceil(days.length / 7);

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [slotColors, setSlotColors] = useState({});
  const [shifts, setShifts] = useState([]);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("roles");
    setUserRole(role);
  }, []);

  // Функция для получения смен по текущему месяцу
  const fetchShifts = async () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthKey = year * 100 + month; // Формат: год * 100 + месяц

    try {
      const shiftsData = await getShiftsByMonth(monthKey);
      setShifts(shiftsData);
    } catch (error) {
      console.error("Ошибка при получении смен:", error);
    }
  };

  useEffect(() => {
    fetchShifts();
  }, [currentDate]);

  const handleDrop = async (event, day, timeOfDay) => {
    event.preventDefault();
  
    const employeeId = event.dataTransfer.getData("employee_id");
    const color = event.dataTransfer.getData("color");
    
    const shift = shifts.find((shift) => {
      console.log(shift);
      const shiftDate = new Date(shift.planned_start);
      return (
        shiftDate.getDate() === day.getDate() &&
        shiftDate.getMonth() === day.getMonth() &&
        shiftDate.getFullYear() === day.getFullYear())
    });
  
    if (!shift) {
      alert("Смена не найдена");
      return;
    }
  
    try {
      if (employeeId) {
        // Проверяем, не добавлен ли уже сотрудник
        if (shift.employees?.some(e => e.id.toString() === employeeId)) {
          alert("Сотрудник уже находится в этой смене");
          return;
        }
        await addEmployeeToShift(shift.id, employeeId);
        alert("Сотрудник успешно добавлен в смену.");
      } else {
        await addMeToShift(shift.id);
        alert("Вы успешно добавлены в смену.");
      }
  
      await fetchShifts();
  
      const key = `${day.getDate()}-${day.getMonth()}-${day.getFullYear()}-${timeOfDay}`;
      setSlotColors((prev) => ({
        ...prev,
        [key]: color,
      }));
    } catch (error) {
      console.error("Ошибка:", error);
      alert(error.message || "Ошибка при добавлении в смену");
    }
  };

  const handleRemoveFromShift = async (shiftId) => {
    try {
      await removeMeFromShift(shiftId);
      alert("Вы успешно удалены из смены.");
      fetchShifts();
    } catch (error) {
      console.error("Ошибка при удалении из смены:", error);
      alert("Ошибка при удалении из смены.");
    }
  };

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const days = getDaysInMonth(year, month);
  const weeks = getWeeksInMonth(days);

  const handlePrevMonth = () => {
    setCurrentDate(new Date(year, month - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(year, month + 1, 1));
  };

  const isCurrentDay = (day) =>
    day &&
    day.getDate() === new Date().getDate() &&
    day.getMonth() === new Date().getMonth() &&
    day.getFullYear() === new Date().getFullYear();

  return (
    <Styled.CalendarWrapper>
      <Styled.BackgroundLayer />
      <Styled.Header>
        <Styled.Title>Календарь</Styled.Title>
        <Styled.MonthsSwitcher>
          <Styled.NavButton
            src={ReturnButton}
            alt="Назад"
            onClick={handlePrevMonth}
          />
          <Styled.MonthContainer>
            <p>
              {months[month]} {year}
            </p>
          </Styled.MonthContainer>
          <Styled.NavButton
            src={ForwardButton}
            alt="Вперед"
            onClick={handleNextMonth}
          />
        </Styled.MonthsSwitcher>
      </Styled.Header>

      <Styled.UnifiedTable>
        <Styled.DaysOfWeekContainer>
          <tr>
            {[" ", ...daysOfWeek].map((header, index) => (
              <Styled.DayOfWeek key={index}>{header}</Styled.DayOfWeek>
            ))}
          </tr>
        </Styled.DaysOfWeekContainer>

        <Styled.TableBody>
          {[...Array(weeks)].map((_, weekIndex) => {
            const weekDays = days.slice(weekIndex * 7, (weekIndex + 1) * 7);

            return (
              <tr key={weekIndex}>
                <Styled.DayCell>
                  <Styled.EmptyRow> </Styled.EmptyRow>
                  <Styled.WeekColumn>
                    <Styled.WeekNum>{weekIndex + 1}-я неделя</Styled.WeekNum>
                    <Styled.TimeColumn>
                      {["утро", "день", "ночь"].map((timeOfDay, index) => (
                        <Styled.TimeItem key={index}>
                          <Styled.TimeText>{timeOfDay}</Styled.TimeText>
                          <Styled.TimeIcon
                            src={
                              timeOfDay === "утро"
                                ? Morning
                                : timeOfDay === "день"
                                ? Day
                                : Night
                            }
                            alt={timeOfDay}
                          />
                        </Styled.TimeItem>
                      ))}
                    </Styled.TimeColumn>
                  </Styled.WeekColumn>
                </Styled.DayCell>

                {weekDays.map((day, index) => (
                  <Styled.DateBorder
                    key={index}
                    isCurrentDay={isCurrentDay(day)}
                  >
                    <Styled.DayCell>
                      <Styled.DateContainer>
                        {day instanceof Date ? day.getDate() : ""}
                      </Styled.DateContainer>
                    </Styled.DayCell>
                    <Styled.TimeSlot
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => handleDrop(e, day, "утро")}
                      style={{
                        backgroundColor:
                          slotColors[
                            `${day?.getDate()}-${day?.getMonth()}-${day?.getFullYear()}-утро`
                          ] || "transparent",
                      }}
                    ></Styled.TimeSlot>

                    <Styled.TimeSlotWrapper>
                      <Styled.TimeSlot
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e, day, "день-1")}
                        style={{
                          backgroundColor:
                            slotColors[
                              `${day?.getDate()}-${day?.getMonth()}-${day?.getFullYear()}-день-1`
                            ] || "transparent",
                        }}
                      ></Styled.TimeSlot>

                      <Styled.TimeSlot
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e, day, "день-2")}
                        style={{
                          backgroundColor:
                            slotColors[
                              `${day?.getDate()}-${day?.getMonth()}-${day?.getFullYear()}-день-2`
                            ] || "transparent",
                        }}
                      ></Styled.TimeSlot>
                    </Styled.TimeSlotWrapper>

                    <Styled.TimeSlot
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => handleDrop(e, day, "ночь")}
                      style={{
                        backgroundColor:
                          slotColors[
                            `${day?.getDate()}-${day?.getMonth()}-${day?.getFullYear()}-ночь`
                          ] || "transparent",
                      }}
                    ></Styled.TimeSlot>
                  </Styled.DateBorder>
                ))}
              </tr>
            );
          })}
        </Styled.TableBody>
      </Styled.UnifiedTable>
    </Styled.CalendarWrapper>
  );
};

export default Calendar;