const BASE_URL = "https://core.andreis-vibes.ru";

const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : null;
};

const getShiftsByMonth = async (month) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/month/${month}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при получении смен");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при получении смен:", error);
      throw error;
    }
  };
  
  const addEmployeeToShift = async (shiftId, employeeId) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(
        `${BASE_URL}/admin_shift/${shiftId}?employee_id=${employeeId}`,
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Ошибка при добавлении сотрудника в смену");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при добавлении сотрудника в смену:", error);
      throw error;
    }
  };

  const removeEmployeeFromShift = async (shiftId, employeeId) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/${shiftId}/employee?employee_id=${employeeId}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при удалении сотрудника из смены");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при удалении сотрудника из смены:", error);
      throw error;
    }
  };
  
  const addMeToShift = async (shiftId) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/${shiftId}/me`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при добавлении себя в смену");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при добавлении себя в смену:", error);
      throw error;
    }
  };
  
  const removeMeFromShift = async (shiftId) => {
    const token = getCookie("authToken");
    if (!token) throw new Error("Токен отсутствует, выполните вход");
  
    try {
      const response = await fetch(`${BASE_URL}/admin_shift/${shiftId}/me`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Ошибка при удалении себя из смены");
      }
  
      return await response.json();
    } catch (error) {
      console.error("Ошибка при удалении себя из смены:", error);
      throw error;
    }
  };

  export { 
    getShiftsByMonth,
    addEmployeeToShift,
    removeEmployeeFromShift,
    addMeToShift,
    removeMeFromShift,
  };
  