import styled from "styled-components";
import { fontSizes } from "../../../CommonComponents/Sizes.jsx";

const pxToRem = (px) => `${px / 16}rem`;

export const CalendarWrapper = styled.div`
  position: relative;
  color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: ${pxToRem(1342)};

  @media (max-width: 1440px) {
    max-width: ${pxToRem(1200)};
  }

  @media (max-width: 1024px) {
    max-width: ${pxToRem(900)};
  }
`;

export const BackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 80%;
  background: #281e71;
  mix-blend-mode: multiply;
  z-index: -1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  font-size: ${pxToRem(32)};
  font-weight: 600;
  text-align: center;
  padding: ${pxToRem(36)} ${pxToRem(31)};
  background: #101820;
  border-radius: 4px 4px 0px 0px;

  @media (max-width: 1024px) {
    font-size: ${pxToRem(24)};
    padding: ${pxToRem(20)} ${pxToRem(31)};
  }
`;

export const MonthsSwitcher = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(20)};
  margin: 1rem auto;
`;

export const MonthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: ${fontSizes.heading};
  font-weight: 600;
  text-transform: uppercase;
  color: white;

  @media (max-width: 1024px) {
    font-size: ${fontSizes.subheading};
  }
`;

export const NavButton = styled.img`
  cursor: pointer;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};

  @media (max-width: 1440px) {
    width: ${pxToRem(36)};
    height: ${pxToRem(36)};
  }

  @media (max-width: 1024px) {
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
  }
`;

export const UnifiedTable = styled.table`
  background: #101820;
  width: 100%;
`;

export const DaysOfWeekContainer = styled.thead`
  display: table-header-group;
  width: 100%;

  tr {
    display: flex;
    width: 100%;
  }
`;

export const DayOfWeek = styled.th`
  display: table-cell;
  text-align: center;
  font-size: ${fontSizes.subheading};
  font-weight: 400;
  background: #101820;
  border: 0.125rem solid #95e0b2;
  border-bottom: none;
  height: ${pxToRem(35)};
  &:first-child {
    width: calc(100% * 1.45 / 8);
  }

  &:not(:first-child) {
    width: calc(100% / 8);
  }

  &:first-child {
    border: none;
    // flex: 1.51;
    padding: ${pxToRem(7)} ${pxToRem(40)} ${pxToRem(7)} ${pxToRem(39)};
  }

  &:last-child {
    border-right: 0.125rem solid #95e0b2;
  }

  @media (max-width: 1440px) {
    font-size: ${pxToRem(20)};
    height: ${pxToRem(30)};
    &:first-child {
      padding: ${pxToRem(7)} ${pxToRem(49)} ${pxToRem(7)} ${pxToRem(48)};
    }

    &:first-child {
      width: calc(100% * 2.07 / 8);
    }
  }

  @media (max-width: 1024px) {
    font-size: ${pxToRem(14)};
    height: ${pxToRem(25)};
    &:first-child {
      //flex: 2.05;
      padding: ${pxToRem(7)} ${pxToRem(60)} ${pxToRem(7)} ${pxToRem(60)};
    }
    &:first-child {
      width: calc(100% * 2.29 / 8);
    }
  }
`;

export const DateCell = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  font-size: ${fontSizes.subheading};
  font-weight: 400;
  background: #101820;
  border: 0.125rem solid #95e0b2;
  border-right: none;

  &:first-child {
    border: none;
    flex: 1.49;
  }

  &:last-child {
    border-right: 0.125rem solid #95e0b2;
  }

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptMedium};
  }
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;

  tr {
    display: flex;
    width: 100%;
  }
`;

export const WeekColumn = styled.td`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: none;

  tr {
    display: flex;
    width: 100%;
  }
`;

export const WeekNum = styled.div`
  padding: ${pxToRem(7)} ${pxToRem(37)} ${pxToRem(7)} ${pxToRem(21)};
  font-size: ${fontSizes.subheading};
  background: #101820;
  color: white;
  text-align: left;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
    padding: ${pxToRem(7)} ${pxToRem(45)} ${pxToRem(7)} ${pxToRem(23)};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptMedium};
    padding: ${pxToRem(7)} ${pxToRem(28)} ${pxToRem(7)} ${pxToRem(18)};
  }
`;

export const DateBorder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  box-sizing: border-box;
  border-right: none;
  border: 0.125rem solid #95e0b2;
  z-index: 1;

  &:nth-child(8n) {
    border-right: 0.125rem solid #95e0b2;
  }

  position: relative;

  ${(props) =>
    props.isCurrentDay &&
    `&::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.25rem;
    background: linear-gradient(180deg, #95E0B2 0%, #7C74BF 50.5%, #281E71 100%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }`}
`;

export const DayCell = styled.td`
  flex: 1;
  border-bottom: 0.125rem solid #95e0b2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;
  position: relative;
`;

export const DateContainer = styled.div`
  height: ${pxToRem(29)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes.subheading};
  box-sizing: border-box;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptMedium};
  }
`;

export const EmptyRow = styled.tr`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  border-top: 0.125rem solid #95e0b2;
  border-bottom: 0.125rem solid #95e0b2;
`;

export const TimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  background: #101820;
  width: 100%;
`;

export const TimeItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(26)};
  height: ${pxToRem(23)};
  font-size: 0.8rem;
  padding: 0 ${pxToRem(12)};
  border: 0.125rem solid #95e0b2;
  border-bottom: none;
  border-right: none;

  &:first-child {
    border-top: none;
  }
`;

export const TimeText = styled.span`
  font-size: ${fontSizes.button};
  font-weight: 400;

  @media (max-width: 1440px) {
    font-size: ${fontSizes.button};
  }

  @media (max-width: 1024px) {
    font-size: ${fontSizes.adaptMedium};
  }
`;

export const TimeIcon = styled.img`
  width: ${pxToRem(15)};
  height: ${pxToRem(15)};
`;

export const TimeSlotWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: none;
  border-bottom: none;
`;

export const TimeSlot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${pxToRem(23)};
  width: 100%;
  background-color: #101820;
  color: #95e0b2;
  border: 0.125rem solid #444444;
  border-right: none;
`;
