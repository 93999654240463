import React, { useRef, useEffect, useState } from "react";
import { fetchProfileData } from "../../shared/api/SignIn";
import * as Styled from "./StyleProfile.jsx";
import {
  updateTelegram,
  updateEmail,
  updatePhoneNumber,
  updateProfileData,
} from "../../shared/api/Profile.jsx";
import AnimatedGradientBackground from "../AnimatedGradient.jsx";
import {
  MaleIcon,
  FemaleIcon,
  Galochka,
  Timer,
  CopyArrow,
  SearchIcon,
  Discord,
  DiscordWhite,
  VK,
  VKWhite,
  Facebook,
  FacebookWhite,
  Instagram,
  InstagramWhite,
  Twitter,
  TwitterWhite,
  TikTok,
  TikTokWhite,
  WhatsApp,
  WhatsAppWhite,
  Zoom,
  ZoomWhite,
  YouTube,
  YouTubeWhite,
  Rutube,
  RutubeWhite,
  Twitch,
  TwitchWhite,
  Steam,
  SteamWhite,
  EpicGames,
  EpicGamesWhite,
  FACEIT,
  FACEITWhite,
  Reddit,
  RedditWhite,
  Trash,
} from "../../img/icons";
import NotificationsPanel from "./NotificationsPanel.jsx";
import CopyNotificationMessage from "./CopyNotification.jsx";
import ProfileHeader from "./ProfileHeader/ProfileHeader.jsx";
import AddSocialsLinks from "./AddSocialsLinks.jsx";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    social_media: {},
  });
  const [error, setError] = useState(null);
  const [copyMessage, setCopyMessage] = useState(""); // Состояние для сообщения о копировании
  const [showNotification, setShowNotification] = useState(false); // Состояние для показа уведомления
  const [isEditing, setIsEditing] = useState(false); // Состояние для редактирования профиля
  const [editedFields, setEditedFields] = useState({});
  const [labelWidths, setLabelWidths] = useState([]); // Массив для ширины всех label
  const labelRefs = useRef([]); // Массив для ссылок на каждый label
  // Состояния для соцсетей
  const [activeSocial, setActiveSocial] = useState(null);
  const [editLinkValue, setEditLinkValue] = useState("");

  // Состояния для NotificationsColumn
  const [isEditingNotifications, setIsEditingNotifications] = useState(false);
  const [activeSocialNotification, setActiveSocialNotification] =
    useState(null);

  const fieldLabels = {
    username: "Никнейм",
    date_of_birth: "Дата рождения",
    email: "E-mail",
    phone_number: "Контактный номер",
    telegram: "Аккаунт Telegram",
    career_goals: "О себе",
  };

  const fieldOrder = [
    "username",
    "date_of_birth",
    "email",
    "phone_number",
    "telegram",
    "career_goals",
  ];

  const initialSocialMediaLinks = [
    { key: "vk", name: "ВК", icon: VK, iconWhite: VKWhite },
    {
      key: "facebook",
      name: "Facebook",
      icon: Facebook,
      iconWhite: FacebookWhite,
    },
    { key: "x", name: "X", icon: Twitter, iconWhite: TwitterWhite },
    {
      key: "instagram",
      name: "Instagram",
      icon: Instagram,
      iconWhite: InstagramWhite,
    },
    { key: "tiktok", name: "TikTok", icon: TikTok, iconWhite: TikTokWhite },
    {
      key: "whatsapp",
      name: "WhatsApp",
      icon: WhatsApp,
      iconWhite: WhatsAppWhite,
    },
    { key: "zoom", name: "Zoom", icon: Zoom, iconWhite: ZoomWhite },
    { key: "youtube", name: "YouTube", icon: YouTube, iconWhite: YouTubeWhite },
    { key: "rutube", name: "RuTube", icon: Rutube, iconWhite: RutubeWhite },
    { key: "twitch", name: "Twitch", icon: Twitch, iconWhite: TwitchWhite },
    { key: "steam", name: "Steam", icon: Steam, iconWhite: SteamWhite },
    {
      key: "epic_games",
      name: "Epic Games",
      icon: EpicGames,
      iconWhite: EpicGamesWhite,
    },
    { key: "faceit", name: "FACEIT", icon: FACEIT, iconWhite: FACEITWhite },
    { key: "reddit", name: "Reddit", icon: Reddit, iconWhite: RedditWhite },
    { key: "discord", name: "Discord", icon: Discord, iconWhite: DiscordWhite },
  ];

  const [socialMediaLinks, setSocialMediaLinks] = useState(
    initialSocialMediaLinks
  );
  const [fullSocialMediaList] = useState(initialSocialMediaLinks); // Полный список не изменяется

  const getGenderIcon = (gender) => {
    switch (gender) {
      case "male":
        return MaleIcon;
      case "female":
        return FemaleIcon;
      default:
        return;
    }
  };

  useEffect(() => {
    fetchProfileData()
      .then((data) => {
        if (data?.full_name) {
          localStorage.setItem("full_name", data.full_name);
          console.log("full_name сохранен:", data.full_name);
        }
        setProfileData({
          ...data,
          social_media: data.social_media || {},
        });
        setEditedFields({
          telegram: data.telegram || "",
        });
      })
      .catch(() => setError("Ошибка при загрузке данных профиля"));
  }, []);

  const handleCopy = (displayValue) => {
    if (displayValue !== "Не указано") {
      navigator.clipboard.writeText(displayValue);
      setCopyMessage("Скопировано в буфер обмена!");
      setShowNotification(true);

      // Скрываем уведомление через 3 секунды
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  };

  const handleEditProfile = async () => {
    if (isEditing) {
      try {
        if (
          editedFields.telegram &&
          editedFields.telegram !== profileData.telegram
        ) {
          await updateTelegram(editedFields.telegram);
          setProfileData((prevState) => ({
            ...prevState,
            telegram: editedFields.telegram,
          }));
        }

        if (editedFields.email && editedFields.email !== profileData.email) {
          await updateEmail(editedFields.email);
          setProfileData((prevState) => ({
            ...prevState,
            email: editedFields.email,
          }));
        }

        if (
          editedFields.phone_number &&
          editedFields.phone_number !== profileData.phone_number
        ) {
          await updatePhoneNumber(editedFields.phone_number);
          setProfileData((prevState) => ({
            ...prevState,
            phone_number: editedFields.phone_number,
          }));
        }

        console.log("Изменения успешно применены");
        setEditedFields({});
      } catch (error) {
        console.log("Ошибка при обновлении профиля: " + error.message);
      }
    }
    setIsEditing((prevState) => !prevState);
    setIsEditingNotifications((prevState) => !prevState);
  };

  const handleFieldChange = (field, value) => {
    setEditedFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleApplyChanges = async (key) => {
    try {
      // Проверка на пустую ссылку
      if (!editLinkValue.trim()) {
        console.error("Ссылка для добавления пуста!");
        return;
      }

      // Проверка на корректность URL (например, начинается ли с http:// или https://)
      const urlPattern = /^(http|https):\/\/[^\s$.?#].[^\s]*$/;
      if (!urlPattern.test(editLinkValue)) {
        console.error("Некорректный URL!");
        return;
      }

      const updatedSocialMedia = {
        ...profileData.social_media,
        [key]: editLinkValue, // Добавляем новую соцсеть
      };

      await updateProfileData({ social_media: updatedSocialMedia }); // Отправляем данные на сервер

      // Обновляем локальное состояние
      setProfileData((prev) => ({
        ...prev,
        social_media: updatedSocialMedia,
      }));

      console.log("Соцсеть добавлена:", updatedSocialMedia);
      setEditLinkValue("");
      setActiveSocialNotification(null);
    } catch (error) {
      console.error("Ошибка при добавлении соцсети:", error);
    }
  };

  const updateSocialMediaLink = async (socialKey) => {
    try {
      const updatedSocialMedia = {
        ...profileData.social_media,
        [socialKey]: null,
      };
  
      await updateProfileData({ social_media: updatedSocialMedia }, true);
  
      setProfileData((prev) => ({
        ...prev,
        social_media: updatedSocialMedia,
      }));
  
      console.log("Ссылка на соцсеть обнулена:", socialKey);
    } catch (error) {
      console.error("Ошибка при обновлении соцсети:", error);
    }
  };
  

  // Функция для обновления ширины всех меток
  const updateLabelWidths = () => {
    const widths = labelRefs.current.map((label) =>
      label ? label.offsetWidth : 0
    );
    setLabelWidths(widths);
  };

  useEffect(() => {
    // Обновляем ширину меток при изменении размера окна
    window.addEventListener("resize", updateLabelWidths);

    // Инициализация ширины меток при монтировании компонента
    updateLabelWidths();

    // Очистка обработчика при демонтировании компонента
    return () => {
      window.removeEventListener("resize", updateLabelWidths);
    };
  }, []); // Пустой массив зависимостей, чтобы useEffect сработал только один раз

  useEffect(() => {
    updateLabelWidths();
  }, [isEditing]);

  return (
    <Styled.Profile>
      <AnimatedGradientBackground />

      <ProfileHeader />
      <Styled.ProfileContainer>
        <Styled.Background />
        <Styled.SubTitleContainer>
          <Styled.SubTitle>Профиль пользователя</Styled.SubTitle>
        </Styled.SubTitleContainer>
        <Styled.Info>
          {error ? (
            <Styled.ErrorText>{error}</Styled.ErrorText>
          ) : (
            profileData && (
              <Styled.ProfileInfo>
                <Styled.LeftColumn>
                  {profileData.avatar && (
                    <Styled.Photo src={profileData.avatar} alt="Фото профиля" />
                  )}
                  <Styled.UserInfoContainer>
                    <Styled.UserInfo>
                      <div>
                        <Styled.FullName>
                          {profileData.full_name || "Имя не указано"}
                        </Styled.FullName>
                        <Styled.Position>
                          {profileData.role || "Должность не указана"}
                        </Styled.Position>
                      </div>
                      <Styled.GenderIcon
                        src={getGenderIcon(profileData.gender)}
                        alt="Иконка пола"
                      />
                    </Styled.UserInfo>
                  </Styled.UserInfoContainer>
                  {isEditing && (
                    <Styled.UploadPhotoButton>
                      Загрузить новое фото профиля
                    </Styled.UploadPhotoButton>
                  )}
                </Styled.LeftColumn>

                <Styled.RightColumn
                  isEditing={isEditing}
                  activeSocial={activeSocial}
                >
                  {fieldOrder
                    .filter(
                      (field) => !(isEditing && field === "date_of_birth")
                    )
                    .map((field, index) => {
                      const displayValue = profileData[field] || "Не указано";

                      // Получаем флаги подтверждения
                      const isConfirmed =
                        field === "email"
                          ? profileData.is_email_verified
                          : field === "phone_number"
                          ? profileData.is_phone_number_verified
                          : field === "telegram"
                          ? profileData.is_telegram_verified
                          : false;

                      return (
                        <Styled.ProfileFieldContainer
                          key={field}
                          isLarge={field === "career_goals"}
                          isEditing={isEditing}
                        >
                          {isEditing ? (
                            <>
                              <Styled.FieldLabel
                                ref={(el) => (labelRefs.current[index] = el)}
                                isEditing={isEditing}
                                isCareerGoals={field === "career_goals"}
                              >
                                {fieldLabels[field]}
                              </Styled.FieldLabel>
                              <Styled.Input
                                defaultValue={displayValue}
                                type="text"
                                isEditing={isEditing}
                                labelWidth={labelWidths[index]}
                                isCareerGoals={field === "career_goals"}
                                isLarge={field === "career_goals"}
                                placeholder={`Введите ${fieldLabels[
                                  field
                                ].toLowerCase()}`}
                                onChange={(e) =>
                                  handleFieldChange(field, e.target.value)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <Styled.FieldLabel
                                isCareerGoals={field === "career_goals"}
                              >
                                {fieldLabels[field]}
                              </Styled.FieldLabel>
                              <Styled.FieldValue
                                isLarge={field === "career_goals"}
                              >
                                {displayValue}
                                {displayValue !== "Не указано" &&
                                  field === "telegram" && (
                                    <Styled.CopyIcon
                                      src={CopyArrow}
                                      alt="Скопировать"
                                      onClick={() => handleCopy(displayValue)}
                                    />
                                  )}
                                {displayValue !== "Не указано" &&
                                  (field === "phone_number" ||
                                    field === "telegram" ||
                                    field === "email") && (
                                    <Styled.StatusIcon
                                      src={isConfirmed ? Galochka : Timer}
                                      alt={
                                        isConfirmed
                                          ? "Подтверждено"
                                          : "Ожидание"
                                      }
                                    />
                                  )}
                              </Styled.FieldValue>
                            </>
                          )}
                        </Styled.ProfileFieldContainer>
                      );
                    })}

                  <Styled.SocialMediaFieldContainer activeSocial={activeSocial}>
                    <Styled.SocialMediaTitle>Соцсети:</Styled.SocialMediaTitle>
                    <Styled.SocialMediaLinks>
                      {socialMediaLinks
                        .filter(({ key }) => profileData.social_media?.[key])
                        .map(({ key, iconWhite }) => {
                          const url = profileData.social_media[key];
                          return (
                            <Styled.SocialItem
                              key={key}
                              draggable={isEditing}
                              onDragStart={(e) => {
                                e.dataTransfer.setData("socialKey", key); // Сохраняем ключ в dataTransfer
                              }}
                            >
                              <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                  if (isEditing) e.preventDefault();
                                  if (!isEditing) return;

                                  setActiveSocial((prevActive) =>
                                    prevActive === key ? null : key
                                  );

                                  setEditLinkValue(
                                    profileData.social_media[key] || ""
                                  );
                                }}
                              >
                                <Styled.SocialIcon src={iconWhite} alt={key} />
                              </a>
                            </Styled.SocialItem>
                          );
                        })}
                    </Styled.SocialMediaLinks>
                    {isEditing && (
                      <Styled.TrashIcon
                        src={Trash}
                        alt="Trash"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          e.preventDefault();
                          const socialKey = e.dataTransfer.getData("socialKey");
                          if (socialKey) {
                            setProfileData((prev) => {
                              const updatedSocialMedia = {
                                ...prev.social_media,
                              };
                              updatedSocialMedia[socialKey] = null;

                              return {
                                ...prev,
                                social_media: updatedSocialMedia,
                              };
                            });
                            updateSocialMediaLink(socialKey);
                          }
                        }}
                      />
                    )}
                  </Styled.SocialMediaFieldContainer>
                  {/* Секция редактирования */}
                  {activeSocial && isEditing && (
                    <AddSocialsLinks
                      editLinkValue={editLinkValue}
                      setEditLinkValue={setEditLinkValue}
                      activeSocial={activeSocial}
                      variant="alternative"
                      onClick={handleApplyChanges}
                    />
                  )}

                  <Styled.EditProfileButton
                    isEditing={isEditing}
                    activeSocial={activeSocial}
                    isEditingNotifications={isEditingNotifications}
                    onClick={() => {
                      handleEditProfile();
                      handleApplyChanges();
                    }}
                  >
                    {isEditing
                      ? "Применить изменения"
                      : "Редактировать профиль"}
                  </Styled.EditProfileButton>
                </Styled.RightColumn>

                <Styled.NotificationsColumn isEditing={isEditing}>
                  {isEditing ? (
                    <>
                      <Styled.SearchWrapper>
                        <Styled.SearchInput
                          type="text"
                          placeholder="Добавить соцсеть.."
                          onChange={(e) => {
                            const searchValue = e.target.value.toLowerCase();
                            setProfileData((prev) => ({
                              ...prev,
                              filteredSocialMedia: fullSocialMediaList.filter(
                                ({ key }) =>
                                  key.toLowerCase().includes(searchValue)
                              ),
                            }));
                          }}
                        />
                        <Styled.SearchIcon src={SearchIcon} alt="Поиск" />
                      </Styled.SearchWrapper>

                      <Styled.SocialsList>
                        {(
                          profileData.filteredSocialMedia || fullSocialMediaList
                        ).map(({ key, icon }) => (
                          <Styled.SocialItem key={key}>
                            <Styled.SocialIcon
                              src={icon}
                              alt={key}
                              onClick={() => {
                                setActiveSocialNotification(key);
                              }}
                            />
                            <Styled.SocialName
                              onClick={() => {
                                setActiveSocialNotification(key);
                              }}
                            >
                              {
                                fullSocialMediaList.find(
                                  (item) => item.key === key
                                )?.name
                              }
                            </Styled.SocialName>
                          </Styled.SocialItem>
                        ))}
                      </Styled.SocialsList>

                      {/* Секция редактирования */}
                      {activeSocialNotification && (
                        <AddSocialsLinks
                          editLinkValue={editLinkValue}
                          setEditLinkValue={setEditLinkValue}
                          activeSocial={activeSocialNotification}
                          onClick={() =>
                            handleApplyChanges(activeSocialNotification)
                          }
                          variant="regular"
                        />
                      )}
                    </>
                  ) : (
                    <NotificationsPanel />
                  )}
                </Styled.NotificationsColumn>
              </Styled.ProfileInfo>
            )
          )}
        </Styled.Info>
      </Styled.ProfileContainer>

      {showNotification && (
        <CopyNotificationMessage
          message={copyMessage}
          onClose={() => setShowNotification(false)}
        />
      )}
    </Styled.Profile>
  );
};

export default Profile;
